import { Card } from '@mui/material';
import Logo from 'src/components/LogoSign';
import globalStyles from 'src/utils/constants/globalStyles';

type Props = {
  children?: React.ReactNode;
};
export const AuthWrapper = (props: Props) => {
  return (
    <Card sx={{ ...globalStyles.card, textAlign: 'center', paddingTop: '42px' }}>
      <div style={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
        <Logo height={80} />
      </div>
      {props.children}
    </Card>
  );
};
