import { Business, Person } from '@mui/icons-material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Avatar, Box, Button, Divider, Hidden, lighten, Popover, styled, Tab, Tabs, Typography } from '@mui/material';
import { SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/authentication';
import ProfileTab from './ProfileTab';
import SettingTab from './SettingsTab';
import { useAppSelector } from 'src/hooks/hooks';
const CardWrapper = styled(Box)(
  () => `
            height: 113px;
            width: 262px;
            display: flex;
          `
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.primary.main, 0.5)}
`
);

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};
function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const UserInfo = () => {
  const user = useSelector((state: any) => state.user); //TODO: ADD IS LOADING AND any ONCE WE KNOW WHAT THE USE WILL BE

  const [value, setValue] = useState(0);

  // const handleClose = (): void => {
  //   setOpen(false);
  // };

  const handleChange = (_event: React.SyntheticEvent, newValue: SetStateAction<number>) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { 
		selectedTrader, 
		traders, 
		traderNetworkStatus,
		errors
	} = useAppSelector((state) => state.trader)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logout } = useAuth();

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        color='primary'
        // ref={ref}
        onClick={handleClick}
        variant='text'
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Avatar alt={user?.user?.email ?? ''} src={user?.user?.email ?? ''} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user?.user?.email ?? ''}</UserBoxLabel>

            <UserBoxDescription variant='body2'>{selectedTrader?.name || 'Please select a trader'}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </Button>

      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        sx={{
          marginTop: 1,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display='flex'>
          <Avatar />
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user?.user?.email ?? ''}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{selectedTrader?.name || 'Please select a trader'}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {/* Remove trader info from user info */}
          <ProfileTab handleLogout={logout} />
          {/* <Tabs
            value={value}
            onChange={handleChange}
            aria-label='profile tabs'
            textColor='primary'
            indicatorColor='primary'
          >
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon={<Person style={{ marginBottom: 0, marginRight: '10px' }} />}
              label='Profile'
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              icon={<Business style={{ marginBottom: 0, marginRight: '10px' }} />}
              label='Traders'
              {...a11yProps(1)}
            />
          </Tabs> */}
        </Box>
        {/* <TabPanel value={value} index={0}>
          <ProfileTab handleLogout={logout} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SettingTab />
        </TabPanel> */}
      </Popover>
    </Box>
  );
};

export default UserInfo;
