import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react/index';
import { env } from '../env';
import { getAuth } from 'firebase/auth';

export const baseAPI = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: env.REACT_APP_BASE_API_URL,
    prepareHeaders: async (headers) => {
      const token: string | null = await getAuth()?.currentUser?.getIdToken?.() ?? ''
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  // Actual endpoints injected by codegen in 'api.generated.ts'.
  endpoints: () => ({}),
});
