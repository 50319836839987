import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress, SvgIconProps } from '@mui/material';
import React from 'react';

export interface ButtonProps extends MuiButtonProps {
  label?: string;
  startIcon?: React.ReactElement<SvgIconProps>;
  endIcon?: React.ReactElement<SvgIconProps>;
  loading?: boolean;
}

export const Button = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { startIcon, loading = false, endIcon, label, children, variant = 'contained', ...rest } = props;
  return (
    <MuiButton
      ref={ref}
      variant={variant}
      {...rest}
      startIcon={startIcon && (loading ? <CircularProgress size={20} color='inherit' /> : startIcon)}
      endIcon={endIcon && (loading ? <CircularProgress size={20} color='inherit' /> : endIcon)}
    >
      {children || label}
    </MuiButton>
  );
});
