import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';

export interface CustomTextFieldProps extends OutlinedTextFieldProps {
  helperText?: string;
}

export function CustomTextField(props: CustomTextFieldProps) {
  return (
    <>
      <TextField {...props} />
      {/* {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )} */}
    </>
  );
}

CustomTextField.defaultProps = {
  variant: 'outlined',
};
