import { CreateDto, FindDto, Meter, UpdateDto } from 'switch-trading-shared';
import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';

const OffTakerAPI = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.METER],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createMeter: build.mutation<Meter, CreateDto<Meter>>({
        query: (body) => ({
          url: 'meter',
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.METER],
      }),

      findMeter: build.query<Meter[], FindDto<Meter>>({
        query: (params) => ({
          url: 'meter/find',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.METER],
      }),

      findOneMeter: build.query<Meter, FindDto<Meter>>({
        query: (params) => ({
          url: 'meter/findOne',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.METER],
      }),

      findMeterById: build.query<Meter, { id: string }>({
        query: ({ id }) => ({
          url: `meter/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.METER],
      }),

      updateMeter: build.mutation<Meter, { id: string; body: UpdateDto<Meter> }>({
        query: ({ id, body }) => ({
          url: `meter/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.METER],
      }),

      deleteMeter: build.mutation<Meter[], { id: string }>({
        query: ({ id }) => ({
          url: `meter/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.METER],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateMeterMutation,
  useDeleteMeterMutation,
  useUpdateMeterMutation,
  useFindMeterQuery,
  useFindOneMeterQuery,
  useFindMeterByIdQuery,
} = OffTakerAPI;

export default OffTakerAPI;
