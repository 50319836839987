import { CreateDto, FindDto, OffTaker, Unwrap, UpdateDto } from 'switch-trading-shared';
import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';

const OffTakerAPI = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.OFFTAKER],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>, CreateDto<OffTaker>>({
        query: (body) => ({
          url: 'off-taker',
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOfftaker: build.query<Unwrap<Unwrap<OffTaker>>[], FindDto<OffTaker>>({
        query: (params) => ({
          url: 'off-taker/find',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOneOfftaker: build.query<Unwrap<Unwrap<OffTaker>>, FindDto<OffTaker>>({
        query: (params) => ({
          url: 'off-taker/findOne',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      findOfftakerById: build.query<Unwrap<Unwrap<OffTaker>>, { id: string }>({
        query: ({ id }) => ({
          url: `off-taker/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      updateOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>, { id: string; body: UpdateDto<OffTaker> }>({
        query: ({ id, body }) => ({
          url: `off-taker/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),

      deleteOfftaker: build.mutation<Unwrap<Unwrap<OffTaker>>[], { id: string }>({
        query: ({ id }) => ({
          url: `off-taker/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.OFFTAKER],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateOfftakerMutation,
  useDeleteOfftakerMutation,
  useUpdateOfftakerMutation,
  useFindOfftakerQuery,
  useFindOneOfftakerQuery,
  useFindOfftakerByIdQuery,
} = OffTakerAPI;

export default OffTakerAPI;
