export enum NERSALicenseType {
  GENERATION = 'generation',
  TRADING = 'trading',
}

export enum EnergySource {
  BIOGAS = 'biogas',
  SOLAR = 'solar',
  WIND = 'wind',
  WAVE = 'wave',
}

export enum PhoneNumberType {
  MOBILE = 'mobile',
  WORK = 'work',
  HOME = 'home',
  FAX = 'fax',
}

export enum EmailAddressType {
  PERSONAL = 'personal',
  WORK = 'work',
}

export enum GridType {
  TRANSMISSION_NETWORK = 'transmission_network',
  DISTRIBUTION_NETWORK = 'distribution_network',
}

export enum UserType {
  GENERATOR = 'generator',
  OFF_TAKER = 'offtaker',
}

export enum ReconPeriod {
  HALF_HOURLY = 'half-hourly',
  HOURLY = 'hourly',
  MONTHLY = 'monthly',
}

export enum BucketUnit {
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum ORGANISATION {
  SWITCH = 'switch',
  ENPOWER = 'enpower',
  EXSA = 'exsa',
}
