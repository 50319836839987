import { CreateDto, FindDto, Grid, UpdateDto } from 'switch-trading-shared';
import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';

const OffTakerAPI = api
  .enhanceEndpoints({
    addTagTypes: [ENDPOINT_TAGS.GRID],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createGrid: build.mutation<Grid, CreateDto<Grid>>({
        query: (body) => ({
          url: 'grid',
          method: API_METHODS.POST,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),

      findGrid: build.query<Grid[], FindDto<Grid>>({
        query: (params) => ({
          url: 'grid/find',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      findOneGrid: build.query<Grid, FindDto<Grid>>({
        query: (params) => ({
          url: 'grid/findOne',
          method: API_METHODS.GET,
          params,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      findGridById: build.query<Grid, { id: string }>({
        query: ({ id }) => ({
          url: `grid/findById/${id}`,
          method: API_METHODS.GET,
        }),
        providesTags: [ENDPOINT_TAGS.GRID],
      }),

      updateGrid: build.mutation<Grid, { id: string; body: UpdateDto<Grid> }>({
        query: ({ id, body }) => ({
          url: `grid/${id}`,
          method: API_METHODS.PATCH,
          body,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),

      deleteGrid: build.mutation<Grid[], { id: string }>({
        query: ({ id }) => ({
          url: `grid/${id}`,
          method: API_METHODS.DELETE,
        }),
        invalidatesTags: [ENDPOINT_TAGS.GRID],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateGridMutation,
  useDeleteGridMutation,
  useUpdateGridMutation,
  useFindGridQuery,
  useFindOneGridQuery,
  useFindGridByIdQuery,
} = OffTakerAPI;

export default OffTakerAPI;
