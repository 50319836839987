import { Tariff, CreateDto, UpdateDto  } from 'switch-trading-shared';
import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';

const tariffAPI = api
	.enhanceEndpoints({
		addTagTypes: [ENDPOINT_TAGS.TARIFF],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			getAllTariffs: build.query<any, any>({
				query: (params) => ({
					url: `${ENDPOINT_TAGS.TARIFF}/find`,
					params,
                    method: API_METHODS.GET
				}),
				providesTags: [ENDPOINT_TAGS.TARIFF]
			}),
			findTariffById: build.query<Tariff, { id: string }>({
				query: ({ id }) => ({
					url: `${ENDPOINT_TAGS.TARIFF}/findById/${id}`,
					method: API_METHODS.GET,
				}),
				providesTags: [ENDPOINT_TAGS.TARIFF]
			}),
			deleteTariff: build.mutation<{ success: boolean }, string>({
                query: (id) => ({
                    url: `${ENDPOINT_TAGS.TARIFF}/${id}`,
                    method: API_METHODS.DELETE,
                }),
                invalidatesTags: (_res, _err, id) => [
                    {
                        type: ENDPOINT_TAGS.TARIFF,
                        id,
                    },
                ],
            }),
			createTariff: build.mutation<Tariff, CreateDto<Tariff>>({
                query: (data) => ({
                    url: `${ENDPOINT_TAGS.TARIFF}`,
                    method: API_METHODS.POST,
                    body: data,
                }),
                invalidatesTags: [ENDPOINT_TAGS.TARIFF],
            }),
			updateTariff: build.mutation<Tariff, { id: string, body: UpdateDto<Tariff> }>({
				query: ({ id, body }) => ({
					url: `${ENDPOINT_TAGS.TARIFF}/${id}`,
					method: API_METHODS.PATCH,
					body
				}),
				invalidatesTags: [ENDPOINT_TAGS.TARIFF]
			}),
		})
	});

export const {
	useGetAllTariffsQuery,
	useDeleteTariffMutation,
	useCreateTariffMutation,
	useFindTariffByIdQuery,
	useUpdateTariffMutation
} = tariffAPI;

export default tariffAPI;