import { MailOutline, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Box, CardActions, CardContent, Divider, IconButton, InputAdornment, Link, Tooltip } from '@mui/material';

import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import Checkbox from 'src/components/Selectors/Checkbox/Checkbox';
import { CustomTextField } from 'src/components/Textinput/Textfield';
import globalStyles from '../../../utils/constants/globalStyles';
import { useAuth } from '../../hooks/useAuth';
import { actionTypes, initialState, loginFormReducer } from '../../reducers/loginFormReducer';
import { AuthWrapper } from '../AuthWrapper';

export const Login = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(loginFormReducer, initialState);
  const [showPassword, setShowPassword] = useState(false);
  const { loginWithEmailAndPassword, isAuthenticated, loading } = useAuth();

  const handleClickShowPassword = () => setShowPassword((prevState) => !prevState);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  const loginUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      loginWithEmailAndPassword(state.email.value, state.password.value);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthWrapper>
      {/* <CardContent
        style={{ alignItems: 'center', justifyContent: 'center', gap: 20, display: 'flex', flexDirection: 'column' }}
      >
        // TODO: Add Google and Apple login
      </CardContent>
      <Divider
        style={{
          marginTop: 15,
          paddingLeft: 69,
          paddingRight: 69,
          color: 'grey',
        }}
      >
        or
      </Divider> */}
      <CardContent style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Box
          component='form'
          onSubmit={loginUser}
          sx={{
            '& .MuiTextField-root': { width: '25ch' },
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
          noValidate
          autoComplete='off'
        >
          <div>
            <CustomTextField
              required
              id='outlined-required'
              label='Email'
              placeholder='Email'
              disabled={loading}
              style={globalStyles.textField}
              autoComplete={'email'}
              value={state.email.value}
              // error={!state.email.valid}
              // helperText={state.email.error}
              onChange={(event) =>
                dispatch({
                  type: actionTypes.EMAIL,
                  payload: event.target.value,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <CustomTextField
              id='outlined-password-input'
              label='Password'
              placeholder='Password'
              type={showPassword ? 'text' : 'password'}
              disabled={loading}
              style={globalStyles.textField}
              autoComplete='current-password'
              // error={!state.password.valid}
              // helperText={state.password.error}
              value={state.password.value}
              onChange={(event) =>
                dispatch({
                  type: actionTypes.PASSWORD,
                  payload: event.target.value,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={showPassword ? 'Hide Password' : 'Show Password'}>
                      <IconButton
                        style={{ padding: 0 }}
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Checkbox label={'Remember me'} />
              <Link href='#' underline='always' variant='body2' color={'primary'}>
                Forgot password?
              </Link>
            </div> */}
          </div>

          <CardActions
            sx={{
              justifyContent: 'center',
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
            }}
          >
            <Button variant='contained' type='submit' fullWidth>
              Login
            </Button>
          </CardActions>
        </Box>
      </CardContent>
    </AuthWrapper>
  );
};
