import { Generator, CreateDto, FindDto, UpdateDto, Unwrap } from 'switch-trading-shared';
import { baseAPI as api } from '../api.service';
import { API_METHODS, ENDPOINT_TAGS } from '../config/apiTags';

const GeneratorApi = api
	.enhanceEndpoints({
		addTagTypes: [ENDPOINT_TAGS.GENERATOR]
	})
	.injectEndpoints({
		endpoints: (build) => ({
			createGenerator: build.mutation<Unwrap<Generator>, CreateDto<Generator>>({
				query: (body) => ({
					url: 'generator',
					method: API_METHODS.POST,
					body
				}),
				invalidatesTags: [ENDPOINT_TAGS.GENERATOR]
			}),

			findGenerator: build.query<Unwrap<Generator>[], FindDto<Generator>>({
				query: (params) => ({
					url: 'generator/find',
					method: API_METHODS.GET,
					params
				}),
				providesTags: [ENDPOINT_TAGS.GENERATOR]
			}),

			findGeneratorById: build.query<Unwrap<Generator>, { id: string }>({
				query: ({ id }) => ({
					url: `generator/findById/${id}`,
					method: API_METHODS.GET,
				}),
				providesTags: [ENDPOINT_TAGS.GENERATOR]
			}),

			updateGenerator: build.mutation<Unwrap<Generator>, { id: string, body: UpdateDto<Generator> }>({
				query: ({ id, body }) => ({
					url: `generator/${id}`,
					method: API_METHODS.PATCH,
					body
				}),
				invalidatesTags: [ENDPOINT_TAGS.GENERATOR]
			}),

			deleteGenerator: build.mutation<Unwrap<Generator>[], { id: string }>({
				query: ({ id }) => ({
					url: `generator/${id}`,
					method: API_METHODS.DELETE,
				}),
				invalidatesTags: [ENDPOINT_TAGS.GENERATOR]
			})
		}),
		overrideExisting: false,
	});

export const {
	useCreateGeneratorMutation,
	useDeleteGeneratorMutation,
	useUpdateGeneratorMutation,
	useFindGeneratorByIdQuery,
	useFindGeneratorQuery
} = GeneratorApi;

export default GeneratorApi;
