import { FC, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import pages from './routes';

import { useTrader } from 'src/context/TraderContext';
import AuthGuard from 'src/guards/AuthGuard';
import { useAppSelector } from 'src/hooks/hooks';
import HeaderLayout from 'src/layouts/HeaderLayout';
import Unverified from 'src/pages/Fallbacks/Status/Unverified/Unverified';
import ViewProfile from 'src/pages/Profiles';
import { useFindGeneratorQuery } from 'src/services/trading-rest-api/generator.service';
import { useFindGridQuery } from 'src/services/trading-rest-api/grid.service';
import { useFindMeterQuery } from 'src/services/trading-rest-api/meter.service';
import { useFindOfftakerQuery } from 'src/services/trading-rest-api/off-taker.service';
import { useGetAllTariffsQuery } from 'src/services/trading-rest-api/tariff.service';
import { useFindTradeGroupQuery } from 'src/services/trading-rest-api/trade-group.service';

const Loader = (Component: FC) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

//TODO Change any
const lazyRetry = function (componentImport: any): Promise<any> {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// Pages
const Home = Loader(lazy(() => lazyRetry(() => import('src/pages/Dashboard'))));
const TradingGroups = Loader(lazy(() => lazyRetry(() => import('src/pages/TradingGroups'))));
const CreateTradingGroup = Loader(lazy(() => lazyRetry(() => import('src/pages/TradingGroups/Create'))));
// const UpdateTradingGroup = Loader(lazy(() => lazyRetry(() => import('src/pages/TradingGroups/Update'))));
const ViewTradingGroup = Loader(lazy(() => lazyRetry(() => import('src/pages/TradingGroups/View'))));
const Generators = Loader(lazy(() => lazyRetry(() => import('src/pages/Generators'))));
const CreateGenerator = Loader(lazy(() => lazyRetry(() => import('src/pages/Generators/Create'))));
const UpdateGenerator = Loader(lazy(() => lazyRetry(() => import('src/pages/Generators/Update'))));
const ViewGenerator = Loader(lazy(() => lazyRetry(() => import('src/pages/Generators/View'))));
const OffTakers = Loader(lazy(() => lazyRetry(() => import('src/pages/OffTakers'))));
const CreateOffTaker = Loader(lazy(() => lazyRetry(() => import('src/pages/OffTakers/Create'))));
const UpdateOffTaker = Loader(lazy(() => lazyRetry(() => import('src/pages/OffTakers/Update'))));
const ViewOffTaker = Loader(lazy(() => lazyRetry(() => import('src/pages/OffTakers/View'))));
const Agreements = Loader(lazy(() => lazyRetry(() => import('src/pages/Agreements/Agreements'))));
const Meters = Loader(lazy(() => lazyRetry(() => import('src/pages/Meters'))));
const CreateMeter = Loader(lazy(() => lazyRetry(() => import('src/pages/Meters/Create'))));
const UpdateMeter = Loader(lazy(() => lazyRetry(() => import('src/pages/Meters/Update'))));
const ViewMeter = Loader(lazy(() => lazyRetry(() => import('src/pages/Meters/View'))));
const Tariffs = Loader(lazy(() => lazyRetry(() => import('src/pages/Tariffs'))));
const CreateTariff = Loader(lazy(() => lazyRetry(() => import('src/pages/Tariffs/Create'))));
const Reports = Loader(lazy(() => lazyRetry(() => import('src/pages/Reports'))));
const Users = Loader(lazy(() => lazyRetry(() => import('src/pages/Users/Users'))));
const Traders = Loader(lazy(() => lazyRetry(() => import('src/pages/Traders'))));
const CreateTrader = Loader(lazy(() => lazyRetry(() => import('src/pages/Traders/Create'))));
const Grids = Loader(lazy(() => lazyRetry(() => import('src/pages/Grid'))));
const CreateGrid = Loader(lazy(() => lazyRetry(() => import('src/pages/Grid/Create'))));
const UpdateGrid = Loader(lazy(() => lazyRetry(() => import('src/pages/Grid/Update'))));
const Recons = Loader(lazy(() => lazyRetry(() => import('src/pages/Recons'))));
const ViewRecon = Loader(lazy(() => lazyRetry(() => import('src/pages/Recons/View'))));
const UpdateTariff = Loader(lazy(() => lazyRetry(() => import('src/pages/Tariffs/Update'))));
const Register = Loader(lazy(() => lazyRetry(() => import('src/pages/Authentication/Register'))));
const Login = Loader(lazy(() => lazyRetry(() => import('src/pages/Authentication/Login'))));

const Status404 = Loader(lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Status404/Status404'))));
const Status500 = Loader(lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Status500/Status500'))));
const StatusComingSoon = Loader(
  lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/ComingSoon/ComingSoon')))
);
const StatusMaintenance = Loader(
  lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Maintenance/Maintenance')))
);
const StatusSuccess = Loader(lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Success/Success'))));
const StatusFailure = Loader(lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Failure/Failure'))));
const StatusCancel = Loader(lazy(() => lazyRetry(() => import('src/pages/Fallbacks/Status/Cancel/Cancel'))));

const DynamicTradingGroupBreadcrumb = (data) => {
  const { pickedTrader: selectedTrader } = useTrader();

  const {
    data: tradeGroups,
    isSuccess,
    isLoading,
    isFetching,
  } = useFindTradeGroupQuery(
    {
      trader: selectedTrader?.id ?? '',
    },
    { skip: selectedTrader === undefined || selectedTrader === null }
  );

  return <span>{tradeGroups?.find((ele) => ele.id === data.match.params.tradingGroupId)?.name}</span>;
};

const DynamicGridBreadcrumb = (data) => {
  const { data: grids, isLoading, isFetching, isError, refetch } = useFindGridQuery({});

  return <span>{grids?.find((ele) => ele.id === data.match.params.gridId)?.name}</span>;
};

const DynamicMeterBreadcrumb = (data) => {
  const { data: meters, isLoading, isFetching, isError, refetch } = useFindMeterQuery({});

  return <span>{meters?.find((ele) => ele.id === data.match.params.meterId)?.name}</span>;
};

const DynamicOfftakerBreadcrumb = (data) => {
  const { selectedTradeGroup } = useAppSelector((state) => state.tradeGroup);

  const {
    data: offTakers,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useFindOfftakerQuery(
    {
      tradeGroup: selectedTradeGroup.id ?? '',
    },
    { skip: selectedTradeGroup === undefined }
  );

  return <span>{offTakers?.find((ele) => ele.id === data.match.params.offTakerId)?.name}</span>;
};

const DynamicGeneratorBreadcrumb = (data) => {
  const { selectedTradeGroup } = useAppSelector((state) => state.tradeGroup);

  const {
    data: generators,
    isFetching,
    isLoading,
    isError,
    refetch,
  } = useFindGeneratorQuery(
    {
      tradeGroup: selectedTradeGroup.id ?? '',
    },
    { skip: selectedTradeGroup === undefined }
  );

  return <span>{generators?.find((ele) => ele.id === data.match.params.generatorId)?.name}</span>;
};

const DynamicTariffBreadcrumb = (data) => {
  const { data: tariffs, isFetching, isLoading, isError, refetch } = useGetAllTariffsQuery({});

  return <span>{tariffs?.find((ele) => ele.id === data.match.params.tariffId)?.name}</span>;
};

const routes: any[] = [
  {
    path: '',
    element: <BaseLayout />,
    /**
     * All children within this element will NOT have a Sidebar and top Navbar
     * All children within this element does not need to be authenticated to access
     */
    children: [
      //#region Base
      {
        //Navigate to home when base routed to base path
        path: '/',
        element: <Navigate to={pages.trader.dashboard.path} replace />,
      },
      //#endregion Base
      //#region Auth
      {
        //All authentication routes
        //No navbars are shown as the user is not logged in
        path: pages.auth.root,
        children: [
          {
            path: '',
            element: <Login />,
          },
          {
            path: pages.auth.login.name,
            element: <Login />,
          },
          {
            path: pages.auth.register.name,
            element: <Register />,
          },
        ],
      },

      //#endregion Auth
      //#region Status
      {
        //All status routes
        path: pages.status.root,
        children: [
          {
            path: '',
            element: <Navigate to='404' replace />,
          },
          {
            path: pages.status.unverified.name,
            element: <Unverified />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,
            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
          //TODO: Maybe make paths that shows status within the sidebars as well
          {
            path: pages.status.statusSuccess.name,
            element: <StatusSuccess />,
          },
          {
            path: pages.status.statusFailure.name,
            element: <StatusFailure />,
          },
          {
            path: pages.status.statusCancel.name,
            element: <StatusCancel />,
          },
        ],
      },
      //#endregion Status
      //#region NotFound
      {
        path: '*',
        element: <Status404 />,
      },
      //#endregion NotFound
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <HeaderLayout />
      </AuthGuard>
    ),

    children: [
      {
        path: pages.account.listTraders.name,
        children: [
          {
            path: '',
            element: <Traders />,
          },
          {
            path: pages.account.createTrader.path,
            element: <CreateTrader />,
          },
        ],
      },
    ],
  },
  {
    path: '',
    element: (
      /**
       * All children with this element will have a Sidebar and top Navbar
       * AuthGuard checks that the user is logged in before granting access to its children pages
       */
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      //#region Base
      {
        path: '',
        element: <Navigate to={pages.trader.dashboard.name} replace />,
      },
      //#endregion Base
      //#region Home
      {
        path: pages.trader.root,
        children: [
          {
            path: '',
            element: <Navigate to={pages.trader.dashboard.name} replace />,
          },
          {
            path: pages.trader.dashboard.name,
            element: <Home />,
          },
          {
            path: pages.trader.tradingGroups.name,
            children: [
              {
                path: '',
                element: <TradingGroups />,
              },
              {
                path: pages.trader.tradingGroups.createTradingGroup.path,
                element: <CreateTradingGroup />,
              },
              {
                path: `${pages.trader.tradingGroups.path}/:tradingGroupId`,
                breadcrumb: DynamicTradingGroupBreadcrumb,
                element: <ViewTradingGroup />,
              },
            ],
          },
          {
            path: pages.trader.tradingGroup.name,
            children: [
              {
                path: pages.trader.tradingGroup.viewTradingGroup.path,
                breadcrumb: DynamicTradingGroupBreadcrumb,
                element: <ViewTradingGroup />,
              },
            ],
          },
          {
            path: pages.trader.generator.name,
            children: [
              {
                path: '',
                element: <Generators />,
              },
              {
                path: pages.trader.generator.createGenerator.path,
                element: <CreateGenerator />,
              },
              {
                path: `${pages.trader.generator.path}/:generatorId`,
                breadcrumb: DynamicGeneratorBreadcrumb,
                children: [
                  {
                    path: ``,
                    element: <ViewGenerator />,
                    breadcrumb: DynamicGeneratorBreadcrumb,
                  },
                  {
                    path: 'update',
                    element: <UpdateGenerator />,
                  },
                ],
              },
            ],
          },
          {
            path: pages.trader.offTakers.name,
            children: [
              {
                path: '',
                element: <OffTakers />,
              },
              {
                path: pages.trader.offTakers.createOffTaker.path,
                element: <CreateOffTaker />,
              },
              {
                path: `${pages.trader.offTakers.path}/:offTakerId`,
                breadcrumb: DynamicOfftakerBreadcrumb,
                children: [
                  {
                    path: ``,
                    element: <ViewOffTaker />,
                    breadcrumb: DynamicOfftakerBreadcrumb,
                  },
                  {
                    path: `update`,
                    element: <UpdateOffTaker />,
                  },
                ],
              },
            ],
          },
          {
            path: pages.trader.agreements.name,
            element: <Agreements />,
          },
          {
            path: pages.trader.meters.name,
            children: [
              {
                path: '',
                element: <Meters />,
              },
              {
                path: pages.trader.meters.createMeter.path,
                element: <CreateMeter />,
              },
              {
                path: `${pages.trader.meters.path}/:meterId`,
                breadcrumb: DynamicMeterBreadcrumb,
                children: [
                  {
                    path: ``,
                    element: <ViewMeter />,
                    breadcrumb: DynamicMeterBreadcrumb,
                  },
                  {
                    path: `update`,
                    element: <UpdateMeter />,
                  },
                ],
              },
            ],
          },
          {
            path: pages.trader.tariffs.name,
            children: [
              {
                path: '',
                element: <Tariffs />,
              },
              {
                path: pages.trader.tariffs.createTariff.path,
                element: <CreateTariff />,
              },
              {
                path: `${pages.trader.tariffs.path}/:tariffId`,
                breadcrumb: DynamicTariffBreadcrumb,
                children: [
                  {
                    path: `update`,
                    element: <UpdateTariff />,
                  },
                ],
              },
            ],
          },
          {
            path: pages.trader.reports.name,
            element: <Reports />,
          },
          {
            path: pages.trader.users.name,
            element: <Users />,
          },
          {
            path: pages.trader.grids.name,
            children: [
              {
                path: '',
                element: <Grids />,
              },
              {
                path: pages.trader.grids.createGrid.path,
                element: <CreateGrid />,
              },
              {
                path: `${pages.trader.grids.path}/:gridId`,
                breadcrumb: DynamicGridBreadcrumb,
                children: [
                  {
                    path: `${pages.trader.grids.updateGrid.path}`,
                    element: <UpdateGrid />,
                  },
                ],
              },
            ],
          },
          {
            path: pages.trader.recons.name,
            children: [
              {
                path: '',
                element: <Recons />,
              },
              {
                path: pages.trader.recons.view.path,
                element: <ViewRecon />,
              },
            ],
          },
          {
            path: pages.trader.xero.name,
            children: [
              {
                path: '',
                element: <></>,
              },
            ],
          },
        ],
      },
      //#endregion Home
      //#region Profile
      {
        path: pages.profile.root,
        children: [
          {
            path: pages.profile.view.path,
            element: <ViewProfile />,
          },
          {
            path: pages.profile.edit.path,
            element: <Home />,
          },
        ],
      },
      //#endregion Profile
    ],
  },
];

export default routes;
