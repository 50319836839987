import { Box, SxProps } from '@mui/material';
import logo_long from 'logo_long.png';

export enum LogoVariant {
  PRIMARY = '/static/images/brand/Logo_green_switch.svg',
  SECONDARY = '/static/SwitchLogo_Green.png',
  TERTIARY = '/static/images/brand/Logo_white_switch.svg',
  MONOCHROME = 'monochrome',
  INVERTED = 'inverted',
  MINIMAL = 'minimal',
}

interface ILogoInterface {
  height?: number | string;
  width?: number | string;
  variant?: LogoVariant;
  sxProps?: SxProps;
}

function Logo(props: ILogoInterface) {
  const { height = 'auto', width = 'auto', variant = LogoVariant.PRIMARY } = props;

  return (
    <Box sx={props.sxProps}>
      <img src={logo_long} alt='Logo' height={height} width={width} />
    </Box>
  );
}

export default Logo;
