import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Tooltip } from '@mui/material';

import { selectTheme, themeNames } from 'src/theme';
import { toggleTheme } from 'src/theme/store/theme/themeSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import IconButton from '../IconButton';

const ThemeSwitch = () => {
  const theme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();

  return (
    <IconButton onClick={() => dispatch(toggleTheme({}))}>
      <Tooltip arrow title={theme === themeNames.dark ? 'Light Mode' : 'Dark Mode'}>
        {theme === themeNames.dark ? <LightModeIcon /> : <DarkModeIcon />}
      </Tooltip>
    </IconButton>
  );
};

export default ThemeSwitch;
