import { createContext, ReactNode, useContext, useState } from 'react';
import { TradeGroup } from 'switch-trading-shared';

// Create the context
interface TradeGroupContextType {
	selectedTradeGroup: TradeGroup | null;
	selectTradeGroup: (trader: TradeGroup | null) => void;
}

const TradeGroupContext = createContext<TradeGroupContextType | undefined>(undefined);

// Create a custom hook to use the context
export function useTradeGroup() {
	const context = useContext(TradeGroupContext);
	if (!context) {
		throw new Error('useTradeGroup must be used within a TradeGroupProvider');
	}
	return context;
}

type TradeGroupProviderProps = {
	children: ReactNode;
};

export function TradeGroupProvider({ children }: TradeGroupProviderProps) {
	const [selectedTradeGroup, setPickedTradeGroup] = useState<TradeGroup | null>(() => {
		// Initialize the selectedTradeGroup from localStorage, if available
		const traderFromLocalStorage = localStorage.getItem('selectedTradeGroup');
		return traderFromLocalStorage ? JSON.parse(traderFromLocalStorage) : null;
	});

	const selectTradeGroup = (trader: TradeGroup | null) => {
		setPickedTradeGroup(trader);
		// Save the selected trader to localStorage
		if (trader) {
			localStorage.setItem('selectedTradeGroup', JSON.stringify(trader));
		} else {
			// If trader is null (unselecting), remove it from localStorage
			localStorage.removeItem('selectedTradeGroup');
		}
	};

	const value: TradeGroupContextType = {
		selectedTradeGroup,
		selectTradeGroup,
	};

	return <TradeGroupContext.Provider value={value}>{children}</TradeGroupContext.Provider>;
}

export default TradeGroupContext;
