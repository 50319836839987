const pages = {
  account: {
    root: 'account',
    listTraders: {
      name: 'traders',
      path: '/traders',
    },
    createTrader: {
      name: 'create-trader',
      path: '/traders/create',
    },
    viewTraders: {
      name: 'view-trader',
      path: '/traders/view',
    },
  },

  auth: {
    root: 'auth',
    login: {
      name: 'login',
      path: '/auth/login',
    },
    register: {
      name: 'register',
      path: '/auth/register',
    },
  },

  trader: {
    root: 'trader',
    dashboard: {
      name: 'dashboard',
      path: '/trader/dashboard',
    },
    tradingGroups: {
      name: 'trading-groups',
      path: '/trader/trading-groups',
      createTradingGroup: {
        name: 'create-trading-group',
        path: '/trader/trading-groups/create',
      },
      viewTradingGroup: {
        name: 'view-trading-group',
        path: '/trader/trading-groups/:tradingGroupId',
      },
      updateTradingGroup: {
        name: 'update-trading-group',
        path: '/trader/trading-groups/:tradingGroupId/update',
      },
    },
    tradingGroup: {
      name: 'group',
      path: '/trader/group',
      viewTradingGroup: {
        name: 'view-group',
        path: '/trader/group/:tradingGroupId',
      },
    },
    generator: {
      name: 'generators',
      path: '/trader/generators',
      createGenerator: {
        name: 'create-generators',
        path: '/trader/generators/create',
      },
      updateGenerator: {
        name: 'update-generators',
        path: '/trader/generators/:generatorId/update',
      },
      viewGenerator: {
        name: 'view-generators',
        path: '/trader/generators/:generatorId',
      },
    },
    offTakers: {
      name: 'off-takers',
      path: '/trader/off-takers',
      createOffTaker: {
        name: 'create-off-taker',
        path: '/trader/off-takers/create',
      },
      updateOffTaker: {
        name: 'update-off-taker',
        path: '/trader/off-takers/:offTakerId/update',
      },
      viewOffTaker: {
        name: 'view-off-taker',
        path: '/trader/off-takers/:offTakerId',
      },
    },
    agreements: {
      name: 'agreements',
      path: '/trader/agreements',
      createGeneratorAgreement: {
        name: 'create-generators-agreement',
        path: '/trader/agreements/create',
      },
      createOffTakerAgreement: {
        name: 'create-off-takers-agreement',
        path: '/trader/agreements/create',
      },
    },
    meters: {
      name: 'meters',
      path: '/trader/meters',
      createMeter: {
        name: 'create-meter',
        path: '/trader/meters/create',
      },
      updateMeter: {
        name: 'update-meter',
        path: '/trader/meters/:meterId/update',
      },
      viewMeter: {
        name: 'view-meter',
        path: '/trader/meters/:meterId',
      },
    },
    tariffs: {
      name: 'tariffs',
      path: '/trader/tariffs',
      createTariff: {
        name: 'create-tariff',
        path: '/trader/tariffs/create',
      },
      updateTariff: {
        name: 'create-tariff',
        path: '/trader/tariffs/:tariffId/update',
      },
      viewTariff: {
        name: 'view-tariff',
        path: '/trader/tariffs/:tariffId',
      },
    },
    reports: {
      name: 'reports',
      path: '/trader/reports',
    },
    users: {
      name: 'users',
      path: '/trader/users',
    },
    grids: {
      name: 'grids',
      path: '/trader/grids',
      createGrid: {
        name: 'create-grid',
        path: '/trader/grids/create',
      },
      updateGrid: {
        name: 'update-grid',
        path: '/trader/grids/:gridId/update',
      },
    },
    recons: {
      name: 'recons',
      path: '/trader/recons',
      view: {
        name: 'view-recon',
        path: '/trader/recons/:id',
      },
    },
    xero: {
      name: 'xero',
      path: '/trader/xero',
    },
  },

  profile: {
    root: 'profile',
    view: {
      name: 'profile',
      path: '/profile/view/:userId',
    },
    edit: {
      name: 'profile',
      path: '/profile/edit/:userId',
    },
  },

  status: {
    root: 'status',
    unverified: {
      name: 'unverified',
      path: '/status/unverified',
    },
    statusComingSoon: {
      name: 'coming-soon',
      path: '/status/coming-soon',
    },
    statusMaintenance: {
      name: 'maintenance',
      path: '/status/maintenance',
    },
    status404: {
      name: '404',
      path: '/status/404',
    },
    status500: {
      name: '500',
      path: '/status/500',
    },
    statusSuccess: {
      name: 'success',
      path: '/status/success',
    },
    statusFailure: {
      name: 'failure',
      path: '/status/failure',
    },
    statusCancel: {
      name: 'cancel',
      path: '/status/cancel',
    },
  },
};

export default pages;
