import { ORGANISATION } from 'src/constants';
import { UtilityType } from 'src/constants/utility';

export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.substr(0, n)}...${str.substr(str.length - n, str.length)}`;
  }
  return '';
};

export const addSeparator = (num: string) => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const currencyFormat = (num: any, symbol?: string) => `${symbol ?? ''}${addSeparator(Number(num).toFixed(2))}`;

export const roundUsage = (num: number, decimals: number) => {
  const factorOfTen = Math.pow(10, decimals);
  return Math.round(num * factorOfTen) / factorOfTen;
};

export const unitFormat = (val: number, unit: string) => {
  if (val >= 1_000_000) {
    return `${val / 1_000_000} m${unit}`;
  } else if (val >= 1_000) {
    return `${val / 1_000} k${unit}`;
  } else {
    return `${val} ${unit}`;
  }
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getUtilityUnits = (utilityType?: UtilityType, kilo?: boolean) => {
  switch (utilityType) {
    case UtilityType.WATER:
      if (kilo) {
        return 'kL';
      }

      return 'L';

    case UtilityType.ELECTRICITY:
      if (kilo) {
        return 'kWh';
      }

      return 'wH';

    case UtilityType.GAS:
      if (kilo) {
        return 'm3';
      }

      return 'cm3';

    default:
      return 'unit';
  }
};

export const camelCaseToSpaces = (input: string): string => {
  const spacedString = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  return spacedString;
};

export const formatReduxRTKMutation = (message: string) => {
  const formatCamelCase = camelCaseToSpaces(message);

  const actionMapping = {
    update: 'Updating ',
    create: 'Creating ',
    delete: 'Deleting ',
    read: 'Reading ',
    generate: 'Generating ',
    email: 'Emailing ',
    sync: 'Syncing ',
    reset: 'Resetting ',
  };

  const foundAction = Object.keys(actionMapping).find((action) => message.includes(action));

  if (foundAction) {
    const updatedString = formatCamelCase.replace(
      foundAction,
      actionMapping[foundAction as keyof typeof actionMapping]
    );
    return capitalize(updatedString);
  }

  return formatCamelCase;
};

export const getBrandFullName = () => {
  switch (process.env.REACT_APP_BRAND) {
    case ORGANISATION.SWITCH:
      return 'Switch Energy';
    case ORGANISATION.ENPOWER:
      return 'Enpower';
    case ORGANISATION.EXSA:
      return 'Energy Exchange South Africa';
    default:
      return 'Switch Energy';
  }
};
