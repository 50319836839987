import { alpha, Box, lighten, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';

import { Outlet } from 'react-router-dom';
import Header from '../SidebarLayout/Header';

interface HeaderLayoutProps {
  children?: ReactNode;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100vh',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          overflowY: 'scroll',
          overflowX: 'hidden',
          position: 'static',
          '.MuiPageTitle-wrapper': {
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`,
          },
        }}
      >
        <Header hasSidebar={false} />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
          }}
        >
          <Box display='block'>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

HeaderLayout.propTypes = {
  children: PropTypes.node,
};

export default HeaderLayout;
