import { EskomTOULabels, TimeOfUseWindow } from "switch-trading-shared"

export enum DurationUnit {
    Years = 'years',
    Months = 'months',
    Weeks = 'weeks',
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes',
    Seconds = 'seconds',
}

export enum TARIFF_TYPE {
    STEP = 'step',
    FIXED_FEE = 'fixed',
    FLAT = 'flat',
    TIME_OF_USE = 'time-of-use',
}

export enum TARIFF_PERIOD {
    OFF_PEAK = 'off-peak',
    STANDARD = 'standard',
    PEAK = 'peak',
}

export type Step = {
    start: number
    end?: number
    rate: number
}

export type NewStep = {
    ratePerUnit: number;
    startUnits: number;
    endUnits?: number;
}

export type ToUWindows = {
    weekday: TimeOfUseWindow<EskomTOULabels>,
    sunday: TimeOfUseWindow<EskomTOULabels>,
    saturday: TimeOfUseWindow<EskomTOULabels>
}

export type TimeOfUseSeason = {
    name: string;
    startMonth: number;
    startDayOfMonth: number;
    endMonth: number;
    endDayOfMonth: number;
    windows: ToUWindows;
    ratesPerUnit: { [label: string]: number };
}

export function parseTariffType(str: string): TARIFF_TYPE {
    for (const utilityType of Object.values(TARIFF_TYPE)) {
        if (utilityType === str) return utilityType;
    }

    throw new Error(`Invalid utility type: ${str}`);
}

export interface TariffTypeElement {
    value: TARIFF_TYPE,
    label: string
}

export const TARIFF_TYPES: TariffTypeElement[] = [
    { value: TARIFF_TYPE.STEP, label: 'Step' },
    { value: TARIFF_TYPE.FIXED_FEE, label: 'Fixed-fee' },
    { value: TARIFF_TYPE.FLAT, label: 'Flat' },
    { value: TARIFF_TYPE.TIME_OF_USE, label: 'Time-of-use' }
]
