import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { env } from 'src/env';
import { baseAPI } from 'src/services/api.service';
import themeSlice from '../theme/store/theme/themeSlice';
import userSlice from './user/userSlice';
import { rtkQueryMiddleware } from './middleware';
import traderSlice from './trader/trader.slice';
import tradeGroupSlice from './trade-group/trade-group.slice';

const sentryReduxEnhancer = createReduxEnhancer({
  //TODO: CHECK IF THERE IS INFO WE DON'T WANT TO SEND TO SENTRY
  // actionTransformer: (action) => {
  //     if (action.type === 'GOVERNMENT_SECRETS') {
  //         // Return null to not log the action to Sentry
  //         return null;
  //     }
  //     if (action.type === 'SET_PASSWORD') {
  //         // Return a transformed action to remove sensitive information
  //         return {
  //             ...action,
  //             password: null,
  //         };
  //     }
  //     return action;
  // },
  // stateTransformer: (state) => {
  //     if (state.topSecret.doNotSend) {
  //       // Return null to not send this version of the state.
  //       return null;
  //     }
  //     // Transform the state to remove sensitive information
  //     const transformedState = {
  //       ...state,
  //       topSecret: {
  //         ...state.topSecret,
  //         // Replace sensitive information with something else
  //         nuclearLaunchCodes: 'I love pizza',
  //         // or just remove it entirely
  //         hiddenTreasureLocation: null,
  //       },
  //       // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
  //       giganticState: null,
  //     };
  //     return transformedState;
  //   },
});

const combinedReducer = combineReducers({
  theme: themeSlice,
  user: userSlice,
  trader: traderSlice,
  tradeGroup: tradeGroupSlice,
  [baseAPI.reducerPath]: baseAPI.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'user/resetUser') {
    // Reset the Redux store
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  devTools: env.REACT_APP_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(baseAPI.middleware).concat(rtkQueryMiddleware),
  enhancers: [sentryReduxEnhancer],
});

export default store;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch;
