import { CSSObject, IconButton, styled } from '@mui/material';

export default styled(IconButton)<{
  variant?: 'text' | 'solid' | 'ghost';
}>(({ theme, variant, color }) => {
  const overrides: CSSObject = {};

  if (variant === 'solid') {
    overrides.color = theme.palette.primary.contrastText;
    overrides.backgroundColor = theme.palette.primary.main;
    overrides[':hover'] = {
      backgroundColor: theme.palette.secondary.dark,
    };
  }
  if (variant === 'ghost') {
    overrides.color = theme.palette.secondary.contrastText;
    overrides.backgroundColor = theme.palette.secondary.main;
    overrides[':hover'] = {
      backgroundColor: theme.palette.secondary.dark,
    };
  }
  return {
    ...overrides,
  };
});
