import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { signOutSuccess } from '../UserProfile/slice';
import { toast } from 'react-toastify';
import TraderApiService from 'src/services/axios-rest-api/trader.service';
import { Trader } from 'switch-trading-shared';
import { NetworkState } from '../../constants/network-state';

const traderApiService = new TraderApiService();

export const fetchTraders = createAsyncThunk('traders/getTraders', async (params: { traderId?: string }) => {
  try {
    const res = await traderApiService.getTraders({});

    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const createTrader = createAsyncThunk('traders/createTrader', async (newTrader: { name: string }) => {
  try {
    const res = await traderApiService.createTrader(newTrader);
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const deleteTrader = createAsyncThunk('traders/deleteTrader', async (id: string) => {
  try {
    const res = await traderApiService.deleteTrader(id);
    return {
      ...res,
      _id: id,
    };
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const updateTrader = createAsyncThunk('traders/updateTrader', async (trader: Trader) => {
  try {
    await traderApiService.updateTrader(trader);
    return trader;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

interface traderSliceInterface {
  traderNetworkStatus: {
    traders: NetworkState;
    isUpdatingTrader: NetworkState;
    isCreatingTrader: NetworkState;
    isDeletingTrader: NetworkState;
  };
  errors: {
    isUpdatingTrader: string | undefined;
    isCreatingTrader: string | undefined;
    isDeletingTrader: string | undefined;
  };
  traders: Trader[];
  tradersCount: number;
  selectedTrader: Trader | undefined;
}

const initialState: traderSliceInterface = {
  traderNetworkStatus: {
    traders: NetworkState.NOT_STARTED,
    isUpdatingTrader: NetworkState.NOT_STARTED,
    isCreatingTrader: NetworkState.NOT_STARTED,
    isDeletingTrader: NetworkState.NOT_STARTED,
  },
  errors: {
    isUpdatingTrader: '',
    isCreatingTrader: '',
    isDeletingTrader: '',
  },
  traders: [],
  tradersCount: 0,
  selectedTrader: undefined,
};

const traderSlice = createSlice({
  name: 'traders',
  initialState,
  reducers: {
    updateSelectedTrader(state, action) {
      localStorage.removeItem('selectedTrader');
      localStorage.setItem('selectedTrader', JSON.stringify(action.payload));
      state.selectedTrader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTraders.pending, (state) => {
        state.traderNetworkStatus.traders = NetworkState.PENDING;
      })
      .addCase(fetchTraders.fulfilled, (state, action) => {
        const traders = action.payload?.value;
        const selectedTrader = localStorage.getItem('selectedTrader')
          ? JSON.parse(localStorage.getItem('selectedTrader')!)
          : traders.length
          ? traders[0]
          : null;
        state.selectedTrader = selectedTrader;
        state.traders = traders;
        state.tradersCount = action.payload?.value.length;
        state.traderNetworkStatus.traders = NetworkState.SUCCESS;
      })
      .addCase(fetchTraders.rejected, (state) => {
        toast.error('Error fetching traders');
        state.traderNetworkStatus.traders = NetworkState.ERROR;
      })
      .addCase(createTrader.pending, (state) => {
        state.traderNetworkStatus.isCreatingTrader = NetworkState.PENDING;
      })
      .addCase(createTrader.fulfilled, (state, action) => {
        toast.success('Trader successfully created');
        state.traderNetworkStatus.isCreatingTrader = NetworkState.SUCCESS;
        state.traderNetworkStatus.traders = NetworkState.NOT_STARTED;
      })
      .addCase(createTrader.rejected, (state) => {
        toast.error('Error creating trader');

        state.traderNetworkStatus.isCreatingTrader = NetworkState.ERROR;
      })
      .addCase(deleteTrader.pending, (state) => {
        state.traderNetworkStatus.isDeletingTrader = NetworkState.PENDING;
      })
      .addCase(deleteTrader.fulfilled, (state, action) => {
        const storedId = localStorage.getItem('selectedTrader');

        // If the currently selected application is deleted
        if (storedId === JSON.stringify(action.payload._id)) {
          localStorage.removeItem('selectedTrader');
        }
        toast.success('Trader successfully deleted');
        state.traderNetworkStatus.isDeletingTrader = NetworkState.SUCCESS;
        state.traderNetworkStatus.traders = NetworkState.NOT_STARTED;
      })
      .addCase(deleteTrader.rejected, (state) => {
        toast.error('Error deleting trader');
        state.traderNetworkStatus.isDeletingTrader = NetworkState.ERROR;
        state.selectedTrader = undefined;
      })
      .addCase(updateTrader.pending, (state) => {
        state.traderNetworkStatus.isUpdatingTrader = NetworkState.PENDING;
      })
      .addCase(updateTrader.fulfilled, (state, action) => {
        state.traders = state.traders.map((trader) =>
          trader._id === action.payload?._id ? { ...trader, ...action.payload } : trader
        );
        toast.success('Trader successfully updated');

        state.traderNetworkStatus.isUpdatingTrader = NetworkState.SUCCESS;
      })
      .addCase(updateTrader.rejected, (state) => {
        toast.error('Error updating trader');

        state.traderNetworkStatus.isUpdatingTrader = NetworkState.ERROR;
      });
    // .addCase(resetUser.fulfilled, (state, action) => {
    //     localStorage.removeItem('selectedTrader')
    //     state = initialState
    // })
  },
});

export const { updateSelectedTrader } = traderSlice.actions;

export default traderSlice.reducer;
