export enum UtilityType {
    ELECTRICITY = 'electricity',
    WATER = 'water',
    GAS = 'gas',
    FIXED_FEE = 'fixedFee',
  }
  
  export interface UtilityTypeElement {
    value: UtilityType,
    label: string
  }
  
  export const UtilityTypesArray: UtilityTypeElement[] = [
    { value: UtilityType.ELECTRICITY, label: 'Electricity' },
    { value: UtilityType.WATER, label: 'Water' },
    { value: UtilityType.GAS, label: 'Gas' },
    { value: UtilityType.FIXED_FEE, label: 'Fixed Fee' }
  ]
  
  export interface TableFilter {
    field: string,
    value: string
  }
  
  export function parseUtilityType(str: string): UtilityType {
    for (const utilityType of Object.values(UtilityType)) {
      if (utilityType === str) return utilityType;
    }
  
    throw new Error(`Invalid utility type: ${str}`);
  }
  
  export enum TagType {
    BATTERY = 'battery',
    SOLAR = 'solar',
    GRID = 'grid',
    BOREHOLE = 'borehole',
    MUNICIPAL = 'municipal',
    UNMETERED = 'unmetered'
  }
  
  export const TAG_TYPES = [
    { _id: TagType.BATTERY, name: 'Battery', utility: 'electricity' },
    { _id: TagType.SOLAR, name: 'Solar', utility: 'electricity' },
    { _id: TagType.GRID, name: 'Grid', utility: 'electricity' },
    { _id: TagType.BOREHOLE, name: 'Borehole', utility: 'water' },
    { _id: TagType.MUNICIPAL, name: 'Municipal', utility: 'water' },
    { _id: TagType.UNMETERED, name: 'Unmetered', utility: 'water' },
  ]
  
  export enum DateRangeType {
    TODAY = 'today',
    PAST_24_HOURS = 'past-24-hours',
    PAST_30_DAYS = 'past-30-days',
    WEEK = 'week',
    THIS_MONTH = 'this-month',
    LAST_MONTH = 'last-month',
    CUSTOM = 'custom'
  }
  
  export enum BinIntervalType {
    HOURLY = 60,
    DAILY = 1440
  }
  
  export const BinIntervalOptions = [
    {
      _id: BinIntervalType.HOURLY,
      name: 'Hourly',
    },
    {
      _id: BinIntervalType.DAILY,
      name: 'Daily',
    }
  ]
  
  export const DateRangeOptions = [
    {
      _id: DateRangeType.TODAY,
      name: 'Today',
    },
    {
      _id: DateRangeType.PAST_24_HOURS,
      name: '24 Hours',
    },
    {
      _id: DateRangeType.PAST_30_DAYS,
      name: 'Past 30 Days',
    },
    {
      _id: DateRangeType.WEEK,
      name: 'This Week',
    },
    {
      _id: DateRangeType.THIS_MONTH,
      name: 'This Month',
    },
    {
      _id: DateRangeType.LAST_MONTH,
      name: 'Last Month',
    },
    {
      _id: DateRangeType.CUSTOM,
      name: 'Custom',
    },
  ]
  
  export type UtilityMetricData = {
    title: string;
    total: number,
    average: number,
    min: number,
    max: number,
    start: number,
    end: number,
  }
  