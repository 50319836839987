import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ProfilesComponent from './components/ProfilesComponent';
import { ProfilesWrapper } from './Profiles.styles';

const ProfilePage = () => {

  return (
    <div>
      <ProfilesWrapper>
        <Helmet>
          <title>View Profile</title>
        </Helmet>
        <Container maxWidth={false} sx={{ paddingY: 3 }}>
          <ProfilesComponent />
        </Container>
      </ProfilesWrapper>
    </div>
  );
};

export default ProfilePage;
