import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { signOutSuccess } from '../UserProfile/slice';
import { toast } from 'react-toastify';
import TradeGroupApiService from 'src/services/axios-rest-api/trade-group.service';
import { TradeGroup } from 'switch-trading-shared';
import { NetworkState } from '../../constants/network-state';
import { updateSelectedTrader } from '../trader/trader.slice';

const tradeGroupApiService = new TradeGroupApiService();

export const fetchTradeGroups = createAsyncThunk('tradeGroups/getTradeGroups', async (params: { trader?: string }) => {
  try {
    const res = await tradeGroupApiService.getTradeGroups(params);

    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const createTradeGroup = createAsyncThunk(
  'tradeGroups/createTradeGroup',
  async (newTradeGroup: { name: string }) => {
    try {
      const res = await tradeGroupApiService.createTradeGroup(newTradeGroup);
      return res;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const deleteTradeGroup = createAsyncThunk('tradeGroups/deleteTradeGroup', async (id: string) => {
  try {
    const res = await tradeGroupApiService.deleteTradeGroup(id);
    return {
      ...res,
      _id: id,
    };
  } catch (error: any) {
    throw new Error(error.message);
  }
});

export const updateTradeGroup = createAsyncThunk('tradeGroups/updateTradeGroup', async (tradeGroup: TradeGroup) => {
  try {
    await tradeGroupApiService.updateTradeGroup(tradeGroup);
    return tradeGroup;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

interface tradeGroupSliceInterface {
  tradeGroupNetworkStatus: {
    tradeGroups: NetworkState;
    isUpdatingTradeGroup: NetworkState;
    isCreatingTradeGroup: NetworkState;
    isDeletingTradeGroup: NetworkState;
  };
  errors: {
    isUpdatingTradeGroup: string | undefined;
    isCreatingTradeGroup: string | undefined;
    isDeletingTradeGroup: string | undefined;
  };
  tradeGroups: TradeGroup[];
  tradingGroupsCount: number;
  selectedTradeGroup: TradeGroup | undefined;
}

const initialState: tradeGroupSliceInterface = {
  tradeGroupNetworkStatus: {
    tradeGroups: NetworkState.NOT_STARTED,
    isUpdatingTradeGroup: NetworkState.NOT_STARTED,
    isCreatingTradeGroup: NetworkState.NOT_STARTED,
    isDeletingTradeGroup: NetworkState.NOT_STARTED,
  },
  errors: {
    isUpdatingTradeGroup: '',
    isCreatingTradeGroup: '',
    isDeletingTradeGroup: '',
  },
  tradeGroups: [],
  tradingGroupsCount: 0,
  selectedTradeGroup: undefined,
};

const tradeGroupSlice = createSlice({
  name: 'tradeGroups',
  initialState,
  reducers: {
    updateSelectedTradeGroup(state, action) {
      localStorage.removeItem('selectedTradeGroup');
      localStorage.setItem('selectedTradeGroup', JSON.stringify(action.payload));
      state.selectedTradeGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeGroups.pending, (state) => {
        state.tradeGroupNetworkStatus.tradeGroups = NetworkState.PENDING;
      })
      .addCase(fetchTradeGroups.fulfilled, (state, action) => {
        const tradeGroups = action.payload?.value;

        const selectedTradeGroup = localStorage.getItem('selectedTradeGroup')
          ? JSON.parse(localStorage.getItem('selectedTradeGroup')!)
          : tradeGroups.length
          ? tradeGroups[0]
          : null;
        state.selectedTradeGroup = selectedTradeGroup;

        state.tradeGroups = tradeGroups;
        state.tradingGroupsCount = action.payload?.value.length;
        state.tradeGroupNetworkStatus.tradeGroups = NetworkState.SUCCESS;
      })
      .addCase(fetchTradeGroups.rejected, (state) => {
        toast.error('Error fetching trade groups');
        state.tradeGroupNetworkStatus.tradeGroups = NetworkState.ERROR;
      })
      .addCase(createTradeGroup.pending, (state) => {
        state.tradeGroupNetworkStatus.isCreatingTradeGroup = NetworkState.PENDING;
      })
      .addCase(createTradeGroup.fulfilled, (state, action) => {
        toast.success('Trade group successfully created');
        state.tradeGroupNetworkStatus.isCreatingTradeGroup = NetworkState.SUCCESS;
        state.tradeGroupNetworkStatus.tradeGroups = NetworkState.NOT_STARTED;
      })
      .addCase(createTradeGroup.rejected, (state) => {
        toast.error('Error creating trade group');
        state.tradeGroupNetworkStatus.isCreatingTradeGroup = NetworkState.ERROR;
      })
      .addCase(deleteTradeGroup.pending, (state) => {
        state.tradeGroupNetworkStatus.isDeletingTradeGroup = NetworkState.PENDING;
      })
      .addCase(deleteTradeGroup.fulfilled, (state, action) => {
        const storedId = localStorage.getItem('selectedTradeGroup');

        // If the currently selected application is deleted
        if (storedId === JSON.stringify(action.payload._id)) {
          localStorage.removeItem('selectedTradeGroup');
        }

        toast.success('Trade group successfully deleted');
        state.tradeGroupNetworkStatus.isDeletingTradeGroup = NetworkState.SUCCESS;
        state.tradeGroupNetworkStatus.tradeGroups = NetworkState.NOT_STARTED;
      })
      .addCase(deleteTradeGroup.rejected, (state) => {
        toast.error('Error deleting trade group');

        state.tradeGroupNetworkStatus.isDeletingTradeGroup = NetworkState.ERROR;
        state.selectedTradeGroup = undefined;
      })
      .addCase(updateTradeGroup.pending, (state) => {
        state.tradeGroupNetworkStatus.isUpdatingTradeGroup = NetworkState.PENDING;
      })
      .addCase(updateTradeGroup.fulfilled, (state, action) => {
        toast.success('Trade group successfully updated');

        state.tradeGroups = state.tradeGroups.map((tradingGroup) =>
          tradingGroup._id === action.payload?._id ? { ...tradingGroup, ...action.payload } : tradingGroup
        );

        state.tradeGroupNetworkStatus.isUpdatingTradeGroup = NetworkState.SUCCESS;
      })
      .addCase(updateTradeGroup.rejected, (state) => {
        toast.error('Error updating trade group');

        state.tradeGroupNetworkStatus.isUpdatingTradeGroup = NetworkState.ERROR;
      })
      .addCase(updateSelectedTrader, () => {
        localStorage.removeItem('selectedTradeGroup');
        return initialState;
      });
    // .addCase(resetUser.fulfilled, (state, action) => {
    //     localStorage.removeItem('selectedTradeGroup')
    //     state = initialState
    // })
  },
});

export const { updateSelectedTradeGroup } = tradeGroupSlice.actions;

export default tradeGroupSlice.reducer;
