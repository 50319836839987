import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProfilesWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);
