import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import router from 'src/router/router';
import defineAbilityFor from './config/ability';
import { AbilityContext } from './context/canContext';
import DialogProvider from './context/dialogContext';
import { selectTheme } from './theme/store/theme/themeSlice';

import { AuthProvider } from './authentication';

import { useEffect } from 'react';
import { NetworkState } from './constants';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import Status500 from './pages/Fallbacks/Status/Status500/Status500';
import { fetchTradeGroups } from './store/trade-group/trade-group.slice';
import { fetchTraders } from './store/trader/trader.slice';
import { ThemeProviderWrapper, themeNames } from './theme';

// Sentry.init(sentryConfig);
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const content = useSentryRoutes(router);
  const theme = useAppSelector(selectTheme);
  const dispatch = useAppDispatch();
  //TODO AUTH
  const ability = defineAbilityFor('admin');

  const { traderNetworkStatus, selectedTrader } = useAppSelector((state) => state.trader);
  const { tradeGroupNetworkStatus } = useAppSelector((state) => state.tradeGroup);
  const { status: authStatus } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (
      traderNetworkStatus.traders === NetworkState.NOT_STARTED &&
      authStatus === 'authenticated'
    ) {
      dispatch(fetchTraders({}));
    }

    if (
      traderNetworkStatus.traders === NetworkState.SUCCESS &&
      tradeGroupNetworkStatus.tradeGroups === NetworkState.NOT_STARTED
    ) {
      dispatch(
        fetchTradeGroups({
          trader: selectedTrader.id,
        })
      );
    }
  }, [dispatch, traderNetworkStatus, tradeGroupNetworkStatus, authStatus]);

  return (
    <AuthProvider>
      <ThemeProviderWrapper>
        <Sentry.ErrorBoundary fallback={<Status500 resetErrorBoundary={() => window.location.reload()} />}>
          <AbilityContext.Provider value={ability}>
            <CssBaseline />
            <DialogProvider>
              <ToastContainer theme={theme === themeNames.dark ? 'dark' : 'light'} />
              {content}
            </DialogProvider>
          </AbilityContext.Provider>
        </Sentry.ErrorBoundary>
      </ThemeProviderWrapper>
    </AuthProvider>
  );
}

export default App;
