import { Breadcrumbs as MuiBreadCrumb } from '@mui/material';
import routes from 'src/router/router';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import './customstyles.css';

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);

  // const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //     event.preventDefault();
  //     console.log(event);
  //     console.info('You clicked a breadcrumb.');
  // };

  return (
    <MuiBreadCrumb className='breadcrumbsCustomStyle' aria-label='breadcrumb' separator='›'>
      {breadcrumbs.map(({ match, breadcrumb }: any, index: number) => {
        return <div key={index}>{breadcrumb}</div>
      })}
    </MuiBreadCrumb>
  );
}

export default Breadcrumbs;
